document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-links-list");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, { // eslint-disable-line
      // Optional parameters
      centeredSlides: true,
      centeredSlidesBounds: true,
      freeMode: false,
      rewind: true,
      initialSlide: 1,
      passiveListeners: true,
      watchOverflow: true,
      lazy: true,

      breakpoints: {
        280: {
          slidesPerView: "auto",
          spaceBetween: 23,
          loop: true,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 23,
          loop: false,
        },
        1400: {
          slidesPerView: 3,
          spaceBetween: 75,
          loop: false,
        },
      },
    });
  });
});
